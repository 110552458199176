<template>
  <v-container
    fluid
    fill-height
    class="d-flex align-center justify-center login-container"
  >
    <v-card style="max-width: 600px; width: 100%">
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark class="text-h6">
        <v-btn
          icon
          class="hidden-xs-only text-white"
          :to="{ name: 'PageGuestHome' }"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        First Time Login
      </v-toolbar>
      <v-card-text>
        <AError :api="api"></AError>
        <v-form @submit.prevent="fetch">
          <div>
            <v-label class="text-overline">{{
              $t("model.prop.email")
            }}</v-label>
            <v-text-field
              required
              outlined
              dense
              v-model="form.email"
              @keyup.native.enter="fetch"
              class="mt-2"
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="api.isLoading"
          :color="HRDC_BLUE_THEME_COLOR"
          class="text-decoration-none text-white"
          @click="fetch()"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Api from "@/objects/api";

export default {
  props: {
    email: {
      type: String,
    },
  },
  data: () => ({
    api: new Api(),
    form: {
      email: null,
    },
  }),
  created() {
    if (this.email) this.form.email = this.$_.clone(this.email);
    this.api.setMethod(`POST`);
    this.api.setUrl(`${this.$service.sso}/v1/en/auth/login/email/verify`);
    this.api.setCallbackCompleted((resp) => {
      this.$router.push({
        name: "PageGuestLoginFirstTimeSetPassword",
        params: {
          email: resp.email,
        },
      });
    });
  },
  methods: {
    fetch() {
      this.api.setParams(this.form);
      this.api.fetch();
    },
  },
};
</script>
<style scoped>
.login-container {
  background-color: #fafafb;
  min-height: 100vh; /* Optional: Set minimum height to cover the entire viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    #d4d4d4,
    #e7e7e7,
    #f1f1f1,
    #daf0ff
  );
}
</style>
